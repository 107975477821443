import moment, { Moment } from "moment";
import { parseISOformat } from "../utils/AppDateFormatter";
import { LOGIN_USER } from "../config";

type ApproveStateType = {
	value: string;
	name: string;
};

const APPROVE_STATE = {
	PENDING: { value: "PENDING", name: "Pending" } as ApproveStateType,
	APPROVED: { value: "APPROVED", name: "Approve" } as ApproveStateType,
	DENIED: { value: "DENIED", name: "Decline" } as ApproveStateType,
};

class ApproversResponses {
	responses: Record<string, ApproverResponse>;

	constructor(responses: Record<string, ApproverResponse>) {
		this.responses = responses;
	}

	parseObject(): void {
		let temp: Record<string, ApproverResponse> = {};
		for (let id in this.responses) {
			let approverResponse_obj = Object.assign(
				new ApproverResponse("", undefined),
				this.responses[id]
			);
			temp[id] = approverResponse_obj;
		}
		this.responses = temp;
	}

	updateApproversResponse(approversResponse: ApproverResponse): void {
		this.responses[approversResponse.leafId] = approversResponse;
	}

	getTotalResponsesCount(): number {
		if (this.responses === undefined) {
			return 0;
		}
		return Object.values(this.responses).filter((obj) => obj !== null)
			.length;
	}
}

class ApproverResponse {
	id: string;
	leafId: string;
	name: string;
	status: string;
	comment: string;
	createdOn: Moment;

	constructor(
		leafId: string,
		createdOn: Moment | undefined,
		id: string = LOGIN_USER.email,
		name: string = LOGIN_USER.name,
		comment: string = "",
		status: string = APPROVE_STATE.PENDING.value
	) {
		this.id = id;
		this.leafId = leafId;
		this.name = name;
		this.status = status;
		this.comment = comment;
		this.createdOn = createdOn === undefined ? moment() : moment(createdOn);
	}

	getApproverComment(): string {
		const dateAsDate = moment(this.createdOn).toDate(); // Convert Moment to Date
		return `${parseISOformat(dateAsDate)} : ${this.name} : ${this.comment}`;
	}
}

export { ApproversResponses, ApproverResponse, APPROVE_STATE };
