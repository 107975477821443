import React, { createContext, useContext, ReactNode } from "react";
import { FormConfig, defaultConfig, reloadConfig } from "../config";

const FormContext = createContext<FormConfig | undefined>(undefined);

interface FormContextProviderProps {
	config?: FormConfig;
	children: ReactNode;
}

export const FormContextProvider: React.FC<FormContextProviderProps> = ({
	config = defaultConfig,
	children,
}) => {
	reloadConfig(config);
	return (
		<FormContext.Provider value={config}>{children}</FormContext.Provider>
	);
};

export const useFormConfig = (): FormConfig => {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error(
			"useFormConfig must be used within a FormContextProvider"
		);
	}
	return context;
};
