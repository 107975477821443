import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Link,
	Box,
	Typography,
	Container,
	FormHelperText,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import * as applicantActions from "../store/actions/applicant";
import * as authActions from "../store/actions/auth";
import { SERVER_URL } from "../config";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href={SERVER_URL}>
				SVGA
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function SignIn(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Inputs
	const [aadharNo, setAadharNo] = useState("");
	const [phoneNo, setPhoneNo] = useState("");
	const [isContactRequired, setIsContactRequired] = useState(false);

	const [otp, setotp] = useState("");
	const [show, setshow] = useState(false);
	const [disableAadharInput, setDisableAadharInput] = useState(false);
	const [final, setfinal] = useState("");
	const [isError, setError] = React.useState(false);
	const [errMsg, setErrMsg] = React.useState("");
	const [oError, setOError] = React.useState(false);
	const [oErrMsg, setOErrMsg] = React.useState("");
	const [helperText, setHelperText] = React.useState("");
	const [loading, setLoading] = useState(false); // Added loading state

	const token = useSelector((state) => state.auth.token);

	const loadNumber = () => {
		let temp = {
			aadharNo,
		};
		if (isContactRequired) {
			temp = {
				aadharNo: aadharNo,
				contact: phoneNo,
			};
		}
		setLoading(true); // Set loading to true before the API call
		fetch(`${SERVER_URL}/api/sendOTP`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(temp),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					setPhoneNo(json.contact);
					setHelperText(json.msg);
					setDisableAadharInput(true);
					setshow(true);
				} else {
					setIsContactRequired(true);
					setDisableAadharInput(true);
					setHelperText(json.msg);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const ValidateContact = () => {
		setError(false);
		setErrMsg("");
		let tempError = false;
		if (!aadharNo) {
			setError(true);
			setErrMsg("Please enter contact number to login");
			tempError = true;
		} else if (!aadharNo.match(/^[0-9]+$/)) {
			setError(true);
			setErrMsg("Contact number must be in digits");
			tempError = true;
		} else if (aadharNo.length < 12) {
			setError(true);
			setErrMsg("Contact number can not be less than 12 digits");
			tempError = true;
		} else if (aadharNo.length > 12) {
			setError(true);
			setErrMsg("Contact number can not be more than 12 digits");
			tempError = true;
		} else {
			setError(false);
			setErrMsg("");
			tempError = false;
		}
		return tempError;
	};
	// Sent OTP
	const signin = () => {
		const tempError = ValidateContact();
		if (tempError) return;
		loadNumber();
	};

	const handleChange = (e) => {
		setAadharNo(e.target.value);
		// ValidateContact();
	};

	const handlePhoneNoChange = (e) => {
		setPhoneNo(e.target.value);
		// ValidateContact();
	};

	// Validate OTP
	const ValidateOtp = () => {
		if (otp === null || final === null) return;
		fetch(`${SERVER_URL}/api/verifyOTP`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				aadharNo: aadharNo,
				contact: phoneNo,
				otp: otp,
			}),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					dispatch(
						authActions.login(aadharNo, phoneNo, json.id_token)
					);
					dispatch(
						applicantActions.fetchApplicant(aadharNo, phoneNo)
					);
					// props.history.replace("/home");
				} else {
					setOError(true);
					setOErrMsg(json.msg);
				}
			})
			.catch((err) => {
				console.log(err);
				// alert("Wrong code");
				setOError(true);
				setOErrMsg("Incorrect OTP code");
			});
	};

	if (token != null) {
		navigate("/home", { replace: true });
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, backgroundColor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="contact"
					label="Student's Aadhar Card Number"
					name="aadhar"
					placeholder="Enter 12 digits aadhar number"
					autoFocus
					onChange={handleChange}
					helperText={errMsg}
					error={isError}
					value={aadharNo}
					disabled={disableAadharInput}
				/>
				<FormHelperText>{helperText}</FormHelperText>
				{isContactRequired && (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="contact"
						label="Contact Number"
						name="contact"
						placeholder="Enter 10 digits contact number"
						autoFocus
						onChange={handlePhoneNoChange}
						helperText={errMsg}
						error={isError}
						value={phoneNo}
						disabled={show}
					/>
				)}

				{show && (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="otp"
						label="OTP"
						id="otp"
						error={oError}
						helperText={oErrMsg}
						onChange={(e) => {
							setotp(e.target.value);
						}}
					/>
				)}

				{!show && <div id="recaptcha-container"></div>}
				{!show && (
					<Button
						// id="sign-in-button"
						fullWidth
						variant="contained"
						color="primary"
						sx={{ mt: 1, mb: 2 }}
						onClick={signin}
						disabled={loading}
					>
						{loading ? "Sending OTP..." : "Send OTP"}
					</Button>
				)}
				{show && (
					<Button
						// id="sign-in-button"
						fullWidth
						variant="contained"
						color="primary"
						sx={{ mt: 1, mb: 2 }}
						onClick={ValidateOtp}
					>
						Verify
					</Button>
				)}
				<Button
					// id="sign-in-button"
					fullWidth
					variant="contained"
					color="primary"
					sx={{ mt: 1, mb: 2 }}
					onClick={() => {
						setAadharNo("");
						setotp("");
						setshow(false);
						setError(false);
						setErrMsg("");
						setfinal("");
						setIsContactRequired(false);
						setDisableAadharInput(false);
						setHelperText("");
					}}
				>
					Reset
				</Button>
				<Link href="/changeContact" variant="body2">
					{"Contact changed?"}
				</Link>
			</Box>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
}
