import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Card,
	CardContent,
	Typography,
	Button,
	CircularProgress,
	Box,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import Header from "./header";
import { Link, useLocation } from "react-router-dom";
import { SERVER_URL } from "../config";

const FormPreview = (props) => {
	const location = useLocation();

	const forms = useSelector((state) => state.forms);
	const applicant = useSelector((state) => state.applicant.applicant);

	const formId = props.formId ? props.formId : location.state.formId;

	if (applicant === undefined) {
		return (
			<Box>
				<Typography sx={{ mt: 1, mb: 1, ml: 2 }}>
					Failed to Load
				</Typography>
				<Button
					variant="contained"
					color="primary"
					component={Link}
					sx={{ maxWidth: 220, marginLeft: 10 }}
					to={{
						pathname: "/home",
					}}
				>
					{" "}
					Back to home{" "}
				</Button>
			</Box>
		);
	}

	const application = applicant.getApplication(formId, forms.applications);
	const previewFormData = application;
	const templates = previewFormData.templates;
	const aadharNo = localStorage.getItem("id");

	const CustomImageContainer = ({ value }) => {
		if (!!value && value === "NA&&") {
			return null;
		}
		if (!!value && value.includes(".pdf")) {
			return (
				<a
					href={`${SERVER_URL}/api/get_file/${aadharNo}/${formId}/${value}`}
					target="_blank"
					rel="noreferrer"
				>
					{"Click to view " + value}
				</a>
			);
		}
		if (!!value) {
			return (
				<img
					src={`${SERVER_URL}/api/get_file/${aadharNo}/${formId}/${value}`}
					alt="Loading"
					width="500"
					sx={{ margin: 1 }}
				/>
			);
		}
		if (!!value) {
			return (
				<div className={{ margin: 10 }}>
					<CircularProgress />
				</div>
			);
		}
		return null;
	};

	function PreviewTable() {
		return (
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									backgroundColor: "#6573c3",
									color: "white",
								}}
							>
								<Typography variant="h5">
									{previewFormData.name}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.values(templates).map((section) => {
							if (section.preview) {
								const fields = section.template.fields;
								return Object.values(fields).map((field) => {
									if (field.preview) {
										if (
											field.value === "" ||
											field.value === "NA&&"
										) {
											return null;
										}
										return (
											<TableRow
												key={field.id}
												sx={{
													"&:nth-of-type(odd)": {
														backgroundColor:
															"action.hover",
													},
												}}
											>
												<TableCell>
													<Typography
														sx={{
															fontWeight: "bold",
														}}
													>
														{field.label}
													</Typography>

													{field.type === "upload" ? (
														<CustomImageContainer
															value={field.value}
														/>
													) : field.type ===
													  "materialuipicker" ? (
														<Typography>
															{moment(
																field.value
															).format(
																"DD-MMM-YYYY"
															)}
														</Typography>
													) : (
														<Typography>
															{field.value}
														</Typography>
													)}
												</TableCell>
											</TableRow>
										);
									}
									return null;
								});
							}
							return null;
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	return (
		<Box>
			{props.formId ? null : <Header />}
			<Card
				sx={{
					width: props.formId ? "100%" : "50%",
					mx: "auto",
					"@media (max-width: 1280px)": {
						width: "100%",
					},
				}}
			>
				<CardContent sx={{ padding: 5 }}>
					<PreviewTable />
				</CardContent>
			</Card>
		</Box>
	);
};

export default FormPreview;
