import { LOGIN_USER } from "../config";
import { ApprovalsLeaf } from "./ApprovalsLeaf";
import { APPROVE_STATE, ApproversResponses } from "./ApproversResponses";

class Approvals {
	leaves: ApprovalsLeaf[];
	responses?: ApproversResponses | undefined;
	statuses?: string[] | undefined;
	halt: boolean;

	constructor(leaves: ApprovalsLeaf[] = [], responses?: ApproversResponses) {
		this.leaves = leaves;
		this.responses = responses;
		this.statuses = undefined;
		this.halt = false;
	}

	parseObject(): void {
		let tempLeaves: ApprovalsLeaf[] = []; // Create a temporary array for parsed leaves

		if (this.responses !== undefined) {
			let tempResponses = new ApproversResponses({
				...this.responses.responses,
			});
			// Parse the responses
			tempResponses.parseObject();
			// Update the responses with the parsed version
			this.responses = tempResponses;
		}

		this.leaves.forEach((leaf) => {
			let parsedLeaf = new ApprovalsLeaf(
				leaf.leafId,
				leaf.name,
				leaf.approvers,
				leaf.allowedClaims,
				leaf.mandatory,
				leaf.atleast,
				leaf.leafStatus,
				leaf.allowReapprovalUponDecline,
				leaf.allowEditingUponDecline
			);
			// Set the responses to the provided responses
			// parsedLeaf.responses = this.responses;
			parsedLeaf.responses = this.responses as ApproversResponses; // Use a type assertion here

			// Parse the leaf object
			parsedLeaf.parseObject();

			// Add the parsed leaf to the temporary array
			tempLeaves.push(parsedLeaf);
			// approvalsLeaf_obj.responses = this.responses;
			// approvalsLeaf_obj.parseObject();
			// temp.push(approvalsLeaf_obj);
		});
		this.leaves = tempLeaves;
	}

	getSteps(): string[] {
		return this.leaves.map((leaf) => leaf.name);
	}

	getApprovalInfo(): string[] {
		return this.leaves.map((leaf) => leaf.getApprovalInfo());
	}

	getApplicantMessage(): string {
		const leaf = this.getCurrentLeaf();
		if (leaf !== undefined) {
			const comment = leaf.getApplicantMessage();
			return comment !== undefined ? comment : "";
		}
		return "";
	}

	getTtlSteps(): number {
		return this.leaves.length;
	}

	getApprovalStatus(forceReload: boolean = false): string[] {
		if (this.responses === undefined) {
			return [];
		}
		if (!forceReload && this.statuses !== undefined) {
			return this.statuses;
		}
		this.statuses = this.leaves.map(
			(leaf) => leaf.getApprovalStatus(forceReload) || ""
		);
		return this.statuses;
	}

	getCurrentStep(): number {
		return this.getCurrentLeafIndex();
	}

	getCurrentLeafIndex(): number {
		let statuses = this.getApprovalStatus();
		if (statuses?.includes(APPROVE_STATE.DENIED.value)) {
			let deniedLeafIndex = statuses.indexOf(APPROVE_STATE.DENIED.value);
			if (this.leaves[deniedLeafIndex].allowReapprovalUponDecline) {
				this.halt = false;
				return deniedLeafIndex;
			}
			this.halt = true;
			return deniedLeafIndex;
		}
		return statuses ? statuses.indexOf(APPROVE_STATE.PENDING.value) : -1;
	}

	// Method to get the current leaf
	getCurrentLeaf(): ApprovalsLeaf | undefined {
		const currentIndex = this.getCurrentLeafIndex();
		return currentIndex !== -1 ? this.leaves[currentIndex] : undefined;
	}

	isEditingUponDecline(): boolean {
		const leaf = this.getCurrentLeaf();
		return leaf !== undefined ? leaf.isEditingUponDecline() : false;
	}

	isValidApprover(): boolean {
		if (this.halt) {
			return false;
		}
		const leaf = this.getCurrentLeaf();
		return leaf !== undefined ? leaf.isValidApprover(LOGIN_USER) : false;
	}

	isValidDecliner(): boolean {
		if (this.halt) {
			return false;
		}
		const leaf = this.getCurrentLeaf();
		if (leaf !== undefined) {
			if (
				leaf.getApprovalStatus() === APPROVE_STATE.DENIED.value &&
				leaf.allowReapprovalUponDecline
			) {
				return false;
			}
			return leaf.isValidApprover(LOGIN_USER);
		}
		return false;
	}
}

export { Approvals };
