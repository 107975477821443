import React, { useEffect } from "react";
import {
	FormControl,
	FormHelperText,
	TextField,
	InputLabel,
	Select,
	Stack,
} from "@mui/material";
import {
	SelectChangeEvent,
	SelectInputProps,
} from "@mui/material/Select/SelectInput";

type CondSelectFieldProps = {
	label?: string;
	name: string;
	value: any;
	options: string[];
	error?: any;
	onChange: (e: SelectChangeEvent<{ name?: string; value: unknown }>) => void;
	helperText?: any;
	other?: {
		label: string;
		id: string;
	};
	id?: string;
	setFieldValue: (name: string, value: any) => void;
	disabled?: boolean;
	handleBlur?: () => void;
	placeholder?: string;
};

function CondSelectField(props: CondSelectFieldProps) {
	const [isOther, setIsOther] = React.useState(false);
	const {
		id,
		value,
		options,
		setFieldValue,
		name,
		onChange,
		disabled,
		label,
		handleBlur,
		helperText,
		placeholder,
		error,
	} = props;

	useEffect(() => {
		if (value) {
			if (!options.includes(value)) {
				setIsOther(true);
			} else if (value !== "Other (if other please specify)") {
				setIsOther(false);
			}
			setFieldValue(name, value);
		}
	}, [value]);

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue(name, e.currentTarget.value);
		setFieldValue(name + "other", e.currentTarget.value);
	};

	const handleSelectChange = (
		event: SelectChangeEvent<{ name?: string; value: unknown }>
	) => {
		const selectedValue = event.target.value as string; // Get the selected value directly from the event
		if (selectedValue === "Other (if other please specify)") {
			setIsOther(true);
		} else {
			setIsOther(false);
			setFieldValue(name, selectedValue);
			onChange(event); // Call the passed-in onChange handler
		}
	};

	return (
		<FormControl
			variant="outlined"
			sx={{ paddingRight: 10 }}
			fullWidth
			disabled={disabled}
			size="small"
		>
			<InputLabel htmlFor="outlined-age-native-simple">
				{label}
			</InputLabel>
			<Stack spacing={1}>
				<Select
					native
					name={name}
					defaultValue={
						isOther ? "Other (if other please specify)" : value
					}
					value={isOther ? "Other (if other please specify)" : value}
					onBlur={handleBlur}
					onChange={handleSelectChange}
					label={label}
					autoWidth
					inputProps={{
						name: name,
						id: id,
					}}
				>
					<option aria-label="None" />
					{options.map((opt, index) => (
						<option key={index} value={opt}>
							{opt}
						</option>
					))}
				</Select>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
				{isOther && (
					<TextField
						id={id + "other"}
						label="Other"
						defaultValue={value}
						variant="outlined"
						placeholder={placeholder}
						name={name + "other"}
						onBlur={handleBlur}
						onChange={handleTextChange}
						fullWidth
						disabled={disabled}
						size="small"
						onKeyPress={(e) => {
							if (e.key === "Enter") e.preventDefault();
						}}
					/>
				)}
				{error && (
					<FormHelperText error sx={{ color: "red" }}>
						{error}
					</FormHelperText>
				)}
			</Stack>
		</FormControl>
	);
}

CondSelectField.defaultProps = {
	options: [],
};

export default CondSelectField;
