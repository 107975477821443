//src/models/Template.ts
import { FormField } from "./FormField";
import { ProfileField } from "./ProfileField";

class Template {
	id: string;
	title: string;
	fields: FormField[];
	#fieldIds: Record<string, number> = {};

	constructor(id: string, fields: FormField[] = [], title: string = "") {
		this.id = id;
		this.title = title;
		this.fields = fields;

		this.parseObject();
	}

	parseObject(): void {
		const temp: FormField[] = [];
		this.#fieldIds = {};
		for (let index = 0; index < this.fields.length; index++) {
			const field = this.fields[index];
			const formField = new FormField(
				field.id,
				field.label,
				field.placeholder,
				field.type,
				field.validationType,
				field.value,
				field.mandatory,
				field.options,
				field.validations,
				field.preview,
				field.helperText,
				field.isProfile,
				field.isEditableProfile,
				field.rules,
				field.action,
				field.elementWidth,
				field?.additionalSettings
			);
			temp.push(formField);
			this.#fieldIds[field.id] = index;
		}
		this.fields = temp;
	}

	update_default_values(response: Record<string, any>): void {
		for (const [key, value] of Object.entries(response)) {
			const index = this.fields.findIndex((obj) => obj.id === key);
			const fieldObj = this.fields[index];
			if (index !== -1 && fieldObj) {
				fieldObj.value = value;
			}
		}
	}

	updateFieldProperties(fieldProperties: any): void {
		const fieldIndex = this.#fieldIds[fieldProperties.id];

		if (fieldIndex !== undefined) {
			const updatedField = {
				...this.fields[fieldIndex],
				...fieldProperties,
			};
			this.fields[fieldIndex] = new FormField(
				updatedField.id,
				updatedField.label,
				updatedField.placeholder,
				updatedField.type,
				updatedField.validationType,
				updatedField.value,
				updatedField.mandatory,
				updatedField.options,
				updatedField.validations,
				updatedField.preview,
				updatedField.helperText,
				updatedField.isProfile,
				updatedField.isEditableProfile,
				updatedField.rules,
				updatedField.action,
				updatedField.elementWidth
			);
		}
	}

	getProfile(formId: string): Record<string, ProfileField> {
		const profile: Record<string, ProfileField> = {};
		for (let index = 0; index < this.fields.length; index++) {
			const field = this.fields[index];
			if (field.isProfile) {
				profile[field.id] = new ProfileField(
					field.id,
					field.label,
					field.value,
					field.isEditableProfile,
					formId
				);
			}
		}
		return profile;
	}

	getFieldsIndex(): Record<string, number> {
		return this.#fieldIds;
	}

	getField(fieldId: string): FormField {
		return this.fields[this.#fieldIds[fieldId]];
	}

	getValues(): Record<string, any> {
		const values: Record<string, any> = {};
		for (let index = 0; index < this.fields.length; index++) {
			const field = this.fields[index];
			values[field.id] = field.value;
		}
		return values;
	}
}

export { Template };
