import React from "react";
import jsonLogic from "json-logic-js";
import Textfield from "./Textfield";
import SelectField from "./SelectField";
import TextAreaField from "./TextAreaField";
import RadioButtonField from "./RadioButtonField";
import CheckboxField from "./CheckboxField";
import UploadField from "./UploadField";
import DateSelector from "./DateSelector";
import Text from "./Text";
import CondSelectField from "./CondSelectField";
import { FormikProps } from "formik";
import ImageHolder from "./ImageHolder";
import { Grid } from "@mui/material";
import BlankField from "./BlankField";
import { AdditionalSettings } from "../../models/FormField";

type CommonProps = {
	formId?: string;
	label?: string;
	name?: string;
	type: any;
	options: any;
	placeholder?: string;
	value?: string;
	touched?: boolean;
	error?: string | boolean;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (e: React.ChangeEvent<any>) => void;
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => void;
	helperText?: string;
	rules?: any;
	rules_data?: any;
	disabled?: boolean;
	alt?: string;
	image?: string;
	title?: string;
	additionalSettings?: AdditionalSettings | undefined;
};

type SelectProps = CommonProps & {};

const fieldMap: { [key: string]: React.FC<CommonProps> } = {
	text: Textfield as React.FC<CommonProps>,
	select: SelectField as React.FC<SelectProps>,
	textarea: TextAreaField as React.FC<CommonProps>,
	radio: RadioButtonField as React.FC<CommonProps>,
	checkbox: CheckboxField as React.FC<CommonProps>,
	upload: UploadField as React.FC<CommonProps>,
	materialuipicker: DateSelector as React.FC<CommonProps>,
	date: Textfield as React.FC<CommonProps>,
	statictext: Text as React.FC<CommonProps>,
	condSelect: CondSelectField as React.FC<CommonProps>,
	// imageHolder: ImageHolder as React.FC<CommonProps>,
};

type FieldProps = {
	fields: any[];
	formikProps: FormikProps<any>;
	formId?: string;
	profile?: any;
};

const Fields: React.FC<FieldProps> = ({
	fields,
	formikProps,
	formId,
	profile,
}) => {
	const {
		errors,
		touched,
		values,
		submitCount,
		handleBlur,
		handleChange,
		setFieldValue,
	} = formikProps;
	let data_temp: { [key: string]: any } = {};
	fields.map((item, index) => {
		data_temp[item.id] = values[item.id];
	});

	const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		handleBlur(e);
	};

	return fields.map((item, index) => {
		if (!item.type) {
			throw new Error(
				`Component type ${item.type} is not supported by flexi-form`
			);
		}
		const Component = fieldMap[item.type];
		if (!Component) {
			throw new Error(
				`Component type ${item.type} is not supported by flexi-form`
			);
		}
		let touch = touched.hasOwnProperty(item.id) && touched[item.id];
		let error =
			submitCount > 0 && errors.hasOwnProperty(item.id)
				? errors[item.id]
				: false;

		const condition = jsonLogic.apply(item.rules, data_temp);

		if (!condition && !!item.action && item.action.includes("required")) {
			if (values[item.id] === "" || values[item.id] === undefined) {
				setFieldValue(item.id, "NA&&");
			}
			return (
				<Grid item xs={12} sm={item.elementWidth}>
					<BlankField />
				</Grid>
			);
		} else {
			if (values[item.id] === "NA&&") {
				setFieldValue(item.id, "");
			}
		}
		let disabled = false;
		if (profile != undefined) {
			if (
				profile[item.id] !== undefined &&
				(values[item.id] === undefined || values[item.id] === "")
			) {
				setFieldValue(item.id, profile[item.id].value);
			}
			if (profile[item.id] !== undefined) {
				if (profile[item.id].submitted === true) {
					disabled = !profile[item.id].isEditable;
				}
			}
		}
		return (
			<Grid key={index} item xs={12} sm={item.elementWidth}>
				<Component
					key={index}
					formId={formId}
					label={item.label}
					type={item.type}
					name={item.id}
					placeholder={item.placeholder}
					value={values[item.id]}
					options={item.options}
					touched={touch as boolean}
					error={error as string | boolean}
					handleBlur={onBlurHandler}
					onChange={handleChange}
					setFieldValue={setFieldValue}
					helperText={item.helperText}
					rules={item.rules}
					rules_data={data_temp}
					disabled={disabled}
					additionalSettings={item.additionalSettings}
				/>
			</Grid>
		);
	});
};

export {
	Fields,
	Text,
	Textfield,
	SelectField,
	TextAreaField,
	RadioButtonField,
	CheckboxField,
	UploadField,
	DateSelector,
	CondSelectField,
};
