import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";

interface ResponsiveTypographyProps {
	children: ReactNode;
}

const ResponsiveTypography: React.FC<ResponsiveTypographyProps> = ({
	children,
}) => {
	const isMobile = useMediaQuery("(max-width:600px)");

	const fontSize = isMobile ? "16px" : "24px";

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				py: 1,
			}}
		>
			<Typography sx={{ fontSize: fontSize, fontWeight: "bold" }}>
				{children}
			</Typography>
		</Box>
	);
};

export default ResponsiveTypography;
