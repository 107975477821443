import moment, { Moment } from "moment";
import { FIREBASE_URL } from "../config";
import { ApproverResponse } from "./ApproversResponses";
import { ApplicationForm } from "./ApplicationForm";
import { ApplicationFormValues } from "./ApplicationFormValues";
import { ProfileField } from "./ProfileField";

class Applicant {
	id: string;
	name: string;
	contact: string;
	aadhaar_no: string;
	applications: Record<string, ApplicationForm> = {};
	applications_values: Record<string, ApplicationFormValues> = {};
	profile: Record<string, ProfileField> = {};
	isEligible: boolean;

	constructor(
		id: string,
		name: string,
		contact: string,
		aadhaar_no: string,
		applications: Record<string, ApplicationForm> = {},
		applications_values: Record<string, ApplicationFormValues> = {},
		profile: Record<string, ProfileField> = {},
		isEligible: boolean = false
	) {
		this.id = id;
		this.name = name;
		this.contact = contact;
		this.aadhaar_no = aadhaar_no;
		this.applications = applications;
		this.applications_values = applications_values;
		this.profile = profile;
		this.isEligible = isEligible;
	}

	parseObject(): void {
		this.parseApplications();
		this.parseApplicationValues();
	}

	private parseApplications(): void {
		this.applications = Object.fromEntries(
			Object.entries(this.applications).map(([formId, application]) => {
				const parsedApplication = new ApplicationForm(
					application.id,
					application.name,
					application.subtitle,
					application.templates,
					application.start_time,
					application.end_time,
					application.pretext,
					application.eligibility,
					application.isActive,
					application.submitted,
					application.state,
					application.activeStep,
					application.approvals,
					application.submittedOn,
					application.publishedOn,
					application.lastUpdated
				);

				parsedApplication.parseObject();
				return [formId, parsedApplication];
			})
		);
	}

	private parseApplicationValues(): void {
		this.applications_values = Object.fromEntries(
			Object.entries(this.applications_values).map(
				([formId, applications_values]) => {
					const parsedApplicationValues = new ApplicationFormValues(
						applications_values.id,
						applications_values.values,
						applications_values.eligibility,
						applications_values.isActive,
						applications_values.submitted,
						applications_values.submittedOn,
						applications_values.activeStep,
						applications_values.createdOn,
						applications_values.approvalResponses,
						applications_values.isEditableByApplicant,
						applications_values?.changeLog,
						applications_values?.auditLog
					);

					parsedApplicationValues.parseObject();
					return [formId, parsedApplicationValues];
				}
			)
		);
	}

	isApplied(formId: string): boolean {
		return this.applications_values.hasOwnProperty(formId);
	}

	getAppliedForms(forms: Record<string, ApplicationForm>): ApplicationForm[] {
		const appliedForms: (ApplicationForm | undefined)[] = Object.keys(forms)
			.filter((formId) => this.isApplied(formId))
			.map((formId) => this.getApplication(formId, forms));

		// Filter out undefined values from the array
		const filteredAppliedForms: ApplicationForm[] = appliedForms.filter(
			(form): form is ApplicationForm => form !== undefined
		);

		return filteredAppliedForms;
	}

	getApplication(
		formId: string,
		forms: Record<string, ApplicationForm>
	): ApplicationForm | undefined {
		if (this.applications.hasOwnProperty(formId)) {
			return this.applications[formId];
		} else if (forms.hasOwnProperty(formId)) {
			const newApplication = forms[formId];

			newApplication.updateValues(
				this.getApplicationValues(formId).values
			);
			newApplication.setParameters(this.getApplicationValues(formId));

			this.applications[formId] = newApplication;
			return newApplication;
		}
		return undefined;
	}

	addApproverResponse(
		applicantId: string,
		formId: string,
		approverResponseObj: ApproverResponse // Replace with the specific type of approverResponseObj if available
	): void {
		if (
			this.applications_values.hasOwnProperty(formId) &&
			applicantId === this.id
		) {
			// Update the corresponding ApplicationForm with the response
			if (this.applications.hasOwnProperty(formId)) {
				const temp_form = this.applications[formId];
				temp_form.addApproverResponse(approverResponseObj);

				let isEditingUponDeclineCurrentLeaf =
					temp_form.approvals &&
					temp_form.approvals.isEditingUponDecline();
				if (isEditingUponDeclineCurrentLeaf === undefined) {
					isEditingUponDeclineCurrentLeaf = false;
				}
				this.applications_values[formId].addApproverResponse(
					applicantId,
					approverResponseObj,
					isEditingUponDeclineCurrentLeaf
				);
			}
		}
	}

	getApplicationValues(formId: string): ApplicationFormValues {
		if (this.applications_values.hasOwnProperty(formId)) {
			const existingValues = this.applications_values[formId];

			// Create a new ApplicationFormValues based on the existing one
			const newValues = new ApplicationFormValues(
				existingValues.id,
				existingValues.values,
				existingValues.eligibility,
				existingValues.isActive,
				existingValues.submitted,
				existingValues.submittedOn,
				existingValues.activeStep,
				existingValues.createdOn,
				existingValues.approvalResponses,
				existingValues.isEditableByApplicant,
				existingValues?.changeLog,
				existingValues?.auditLog
			);

			newValues.parseObject();
			return newValues;
		} else {
			return new ApplicationFormValues(formId); // Initialize with just the ID
		}
	}

	updateApplicationTemplate(
		key: number, // Define the type for key
		template: any, // Define the appropriate type for template
		formId: string,
		isSubmit: boolean
	) {
		let application = this.applications[formId];
		let application_values = this.getApplicationValues(formId);

		application.updateTemplate(key, template);

		if (application.isActive && isSubmit) {
			for (let template of application.getTemplateList()) {
				this.updateProfile(template.getProfile(formId), isSubmit);
			}
		}
		application.activeStep = key;
		this.applications[formId] = application;

		application_values.updateValues(application.getFlatternValues());
		application_values.activeStep = key;
		this.applications_values[formId] = application_values;

		if (isSubmit) {
			application.submit();
			application.isEditableByApplicant = false;
			application_values.submit();
		}
	}

	updateProfile(
		profile_fields: Record<string, ProfileField>,
		isSubmit: boolean
	) {
		// TODO, handle isSubmit
		for (const [key, profile_field] of Object.entries(profile_fields)) {
			if (
				this.profile[key] != undefined &&
				this.profile[key].value !== profile_field.value
			) {
				profile_field.submitted = isSubmit;
				this.profile[key] = profile_field;
			}
			if (this.profile[key] == undefined) {
				profile_field.submitted = isSubmit;
				this.profile[key] = profile_field;
			}
		}
	}

	async performNext(token: string): Promise<void> {
		await this.__update_server(token);
	}

	private async __update_server(token: string): Promise<void> {
		if (this.aadhaar_no && this.aadhaar_no !== "") {
			const temp_obj = { ...this, applications: {} };
			const response = await fetch(
				`${FIREBASE_URL}applicants/${temp_obj.aadhaar_no}.json?auth=${token}`,
				{
					method: "PATCH",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(temp_obj),
				}
			);

			if (!response.ok) {
				// Handle error
			}
		}
	}
}

export { Applicant };
