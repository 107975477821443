import React from "react";
import { Typography, Box } from "@mui/material";

interface TextProps {
	label?: string;
	name: string;
	value?: any;
	error?: any;
	onChange: (event: React.ChangeEvent<any>) => void;
}

const Text: React.FC<TextProps> = (props) => {
	return (
		<Box>
			<hr />
			<Typography
				variant="body1"
				sx={{ fontWeight: 550, fontStyle: "italic" }}
			>
				{props.value}
			</Typography>
			<hr />
		</Box>
	);
};

Text.defaultProps = {};

export default Text;
