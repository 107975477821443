import React from "react";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Box from "@mui/material/Box";
import { FormHelperText, TextField } from "@mui/material";

interface DateSelectorProps {
	id?: string;
	label?: string;
	name: string;
	placeholder?: string;
	value: any;
	error?: any;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => void;
	disabled?: boolean;
	touched?: { [key: string]: boolean };
}

const DateSelector: React.FC<DateSelectorProps> = (props) => {
	const handleChange = (value: any) => {
		props.setFieldValue(props.name, value);
	};

	const renderInput = (params: any) => {
		return <TextField {...params} />;
	};

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					views={["day", "month", "year"]}
					label={`${props.label}(MM/DD/YYYY)`}
					value={props.value}
					onChange={handleChange}
					renderInput={renderInput}
					disabled={props.disabled}
				/>
			</LocalizationProvider>
			{props.error && (
				<FormHelperText error>{props.error}</FormHelperText>
			)}
		</Box>
	);
};

export default DateSelector;
