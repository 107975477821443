import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";

interface ImageHolderProps {
	alt: string;
	image?: string;
	// title: string;
}

const ImageHolder: React.FC<ImageHolderProps> = ({ alt, image }) => {
	if (!image) {
		return;
	}
	return (
		<Box>
			<CardMedia
				component="img"
				alt={alt}
				image={image}
				// title={title}
				sx={{
					height: "auto",
					objectFit: "cover",
					backgroundSize: "cover",
					position: "relative",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			/>
		</Box>
	);
};

export default ImageHolder;
