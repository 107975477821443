type ClaimType = {
  value: string;
  name: string;
};

const CLAIMS = {
  AUDITOR: { value: "auditor", name: "Auditor" } as ClaimType,
  ADMIN: { value: "admin", name: "Admin" } as ClaimType,
  APPROVER: { value: "approver", name: "Approver" } as ClaimType,
  SUPERADMIN: { value: "superAdmin", name: "Super Admin" } as ClaimType,
};

class LoginUser {
  id: string;
  email: string;
  name: string;
  claims: string[];
  token: string;

  constructor(id: string, email: string, name: string, claims: string[], token: string) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.claims = claims;
    this.token = token;
  }

  checkIsClaimsAllowed(allowedClaims?: string[]): boolean {
    if (this.claims.includes(CLAIMS.SUPERADMIN.value)) {
      return true;
    }
    if (allowedClaims === undefined || allowedClaims.length === 0) {
      return false;
    }
    const intersection = allowedClaims.filter((val) => this.claims.includes(val));
    return intersection.length > 0;
  }
}

class ApproverUser extends LoginUser {
  isActive: boolean;
  status?: string;
  comment?: string;

  constructor(id: string, name: string, claims: string[], isActive: boolean = true) {
    super(id, "", name, claims, "");
    this.isActive = isActive;
  }

  updateResponse(approversResponses?: { responses?: Record<string, { status?: string; comment?: string }> }): void {
    if (approversResponses !== undefined && approversResponses.responses !== undefined) {
      let temp = approversResponses.responses[this.id];
      this.status = temp?.status;
      this.comment = temp?.comment;
    }
  }
}

export { LoginUser, ApproverUser, CLAIMS };
