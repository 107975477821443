interface FirebaseConfig {
	apiKey: string;
	authDomain: string;
	databaseURL: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
}
interface FormConfig {
	envName: string;
	FIREBASE_URL: string;
	SERVER_URL: string;
	SERVER_URL_IMG: string;
	firebaseConfig: FirebaseConfig;
}

const defaultConfig: FormConfig = {
	envName: "PROD",
	FIREBASE_URL: "https://default-url",
	SERVER_URL: "http://localhost:4000",
	SERVER_URL_IMG: "https://default-url",
	firebaseConfig: {
		apiKey: "default-key",
		authDomain: "default",
		databaseURL: "default-url",
		projectId: "default-id",
		storageBucket: "default-bucket",
		messagingSenderId: "default-id",
		appId: "default-app-id",
	},
};

let FIREBASE_URL: string = defaultConfig.FIREBASE_URL;
let SERVER_URL: string = defaultConfig.SERVER_URL;
let SERVER_URL_IMG: string = defaultConfig.SERVER_URL_IMG;
let firebaseConfig: any = defaultConfig.firebaseConfig; // Replace 'any' with the actual type if known

let LOGIN_USER: any = null; // Replace 'any' with the actual type if known

const setLoginUser = (obj: any) => {
	// Replace 'any' with the actual type if known
	LOGIN_USER = obj;
};

const reloadConfig = (config: any) => {
	FIREBASE_URL = config.FIREBASE_URL;
	SERVER_URL = config.SERVER_URL;
	SERVER_URL_IMG = config.SERVER_URL_IMG;
	firebaseConfig = config.firebaseConfig;
};

export {
	LOGIN_USER,
	FIREBASE_URL,
	SERVER_URL,
	SERVER_URL_IMG,
	firebaseConfig,
	defaultConfig,
	FormConfig,
	setLoginUser,
	reloadConfig,
};
