import React, { ChangeEvent, FC } from "react";
import { TextField, FormHelperText, Box } from "@mui/material";

interface TextAreaFieldProps {
	// id: string;
	label?: string;
	name: string;
	placeholder?: string;
	value?: string | number;
	error?: string | null;
	onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	handleBlur?: () => void;
	helperText?: string;
	disabled?: boolean;
}

const TextAreaField: FC<TextAreaFieldProps> = (props) => {
	return (
		<Box>
			<TextField
				multiline
				sx={{ color: "purple" }}
				// id={props.id}
				label={props.label}
				variant="outlined"
				placeholder={props.placeholder}
				name={props.name}
				value={props.value}
				onBlur={props.handleBlur}
				onChange={props.onChange}
				fullWidth={true}
				helperText={props.error}
				error={Boolean(props.error)}
				minRows={4}
				disabled={props.disabled}
			/>
			<FormHelperText> {props.helperText} </FormHelperText>
		</Box>
	);
};

TextAreaField.defaultProps = {
	label: "",
	name: "",
	placeholder: "",
	value: "",
	error: "",
	helperText: "",
};

export default TextAreaField;
