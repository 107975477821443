import React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { Card, Paper } from "@mui/material";
import ResponsiveTypography from "../../components/ResponsiveTypography";

interface CustomMobileStepperProps {
	children: React.ReactNode;
	application_name: string;
	totalSteps: number;
	isStepperRequired: boolean;
	activeStep: number;
	handleNext: () => void;
	handleBack: () => void;
}

const CustomMobileStepper: React.FC<CustomMobileStepperProps> = ({
	children,
	application_name,
	totalSteps,
	isStepperRequired,
	activeStep,
	handleNext,
	handleBack,
}) => {
	return (
		<>
			{application_name && (
				<Paper elevation={3} square={true}>
					<ResponsiveTypography>
						{application_name}
					</ResponsiveTypography>
				</Paper>
			)}
			{children}
			<Card
				sx={{
					mb: 0.5,
					borderRadius: 1,
					width: "60%",
					marginLeft: "auto",
					marginRight: "auto",
					"@media (max-width: 960px)": {
						width: "100%",
					},
				}}
			>
				{" "}
				{isStepperRequired && (
					<MobileStepper
						variant="progress"
						steps={totalSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button size="small" onClick={handleNext}>
								{activeStep === totalSteps - 1
									? "Finish"
									: "Next"}
							</Button>
						}
						backButton={
							<Button
								size="small"
								onClick={handleBack}
								disabled={activeStep === 0}
							>
								Back
							</Button>
						}
					/>
				)}
			</Card>
		</>
	);
};

export default CustomMobileStepper;
