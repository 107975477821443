// src/Form/index.tsx
import React, { useRef } from "react";
import { FIELD_TYPES, VALIDATION_TYPES } from "./constants";
import TemplateRenderer from "./TemplateRenderer";
import DynamicStepper from "./DynamicStepper";
import { ApplicationForm } from "../models";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { useFormikContext, FormikContextType } from "formik";
import ImageHolder from "./components/ImageHolder";
import { convertMomentDateToString } from "../utils/AppDateFormatter";
import moment, { Moment } from "moment";

interface DynamicFormProps {
	application: ApplicationForm;
	cbSubmit: (
		values: Record<string, any>,
		currentStep: number,
		isFinalSubmit: boolean
	) => void;
	formId: string;
	setIsValid: (isValid: boolean) => void;
	// onFinalSubmit?: () => void;
	FinalSubmitComponent?: React.ComponentType<{
		submittedOn?: Moment | null;
		formData?: Record<string, any>;
	}>;
	// id?: string; not in use
	profile?: any;
}

const DynamicForm: React.FC<DynamicFormProps> = (props) => {
	const {
		application,
		cbSubmit,
		formId,
		// onFinalSubmit,
		profile,
		FinalSubmitComponent,
	} = props;

	const submitFormRef = useRef<() => void>(() => {});
	const templates = application.getTemplateList();
	const [activeStep, setActiveStep] = React.useState(
		application.getCurrentStep()
	);
	const [isFinalSubmit, setIsFinalSubmit] = React.useState(
		application.isFinalSubmit()
	);

	// console.log("isFinalSubmit", isFinalSubmit);

	if (isFinalSubmit) {
		const submittedOn = application.submittedOn;
		const formData = application.getFlatternValues();

		if (FinalSubmitComponent) {
			return (
				<FinalSubmitComponent
					submittedOn={submittedOn}
					formData={formData}
				/>
			);
		} else {
			if (submittedOn) {
				return (
					"Form Submitted, We received your last response as of " +
					convertMomentDateToString(moment(submittedOn)) +
					" ફોર્મ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે"
				);
			}
			return "Form Submitted";
		}
	}

	// console.log("activeStep", activeStep);

	const template = templates[activeStep];
	const totalSteps = templates.length;

	const cbSubmitTmp = (values: any) => {
		console.log("cbSubmitTmp");

		template.update_default_values(values);
		application.updateTemplate(activeStep, template);

		application.nextStep();
		application.submit();
		const ttlSteps = application.getStepList().length;

		cbSubmit(
			values,
			application.getCurrentStep(),
			application.activeStep === ttlSteps
		);
		setActiveStep(application.getCurrentStep());
		setIsFinalSubmit(application.isFinalSubmit());
	};

	const handleNext = () => {
		submitFormRef.current();
	};

	const handleBack = () => {
		application.prevStep();
		setActiveStep(application.getCurrentStep());
	};

	return (
		<Box
			sx={{
				minHeight: "100%",
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<ImageHolder
				alt=""
				image={application.getHeaderImage(template.id)}
			/>
			<DynamicStepper
				application_name={application.name}
				totalSteps={totalSteps}
				activeStep={activeStep}
				handleNext={handleNext}
				handleBack={handleBack}
				steps={application.getStepList()}
			>
				<TemplateRenderer
					cbSubmit={cbSubmitTmp}
					template={template}
					formId={formId}
					submitFormRef={submitFormRef}
					profile={profile}
				/>
			</DynamicStepper>
		</Box>
	);
};

export { FIELD_TYPES, VALIDATION_TYPES, DynamicForm };
