import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../header";
import FormCard from "./FormCard"; // A new component we will create
import { fetchApplicant } from "../../store/actions/applicant";
import * as formsActions from "../../store/actions/forms";
import banner from "../../static/img/banner.png";
import { Typography } from "@mui/material";

const isApplied = (form, applicant) => {
	return applicant.isApplied(form.id);
};

const getFormStatus = (form, applicant, forms) => {
	if (form.isActive) {
		const isFormApplied = isApplied(form, applicant);
		if (isFormApplied) {
			const app = applicant.getApplication(form.id, forms);
			return app.getFormResponseStatus(isFormApplied);
		} else {
			return form.getFormResponseStatus(false);
		}
	}
	return null;
};

const LandingPage = () => {
	const forms = useSelector((state) => state.forms);
	const applicant = useSelector((state) => state.applicant.applicant);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			fetchApplicant(
				localStorage.getItem("id"),
				localStorage.getItem("phoneNo")
			)
		);
	}, []);

	// dispatch(formsActions.createTemplate());

	const renderFormsDetails = () => {
		if (!applicant) return null;

		return Object.keys(forms.applications).map((key) => {
			const form = forms.applications[key];
			const formStatus = getFormStatus(
				form,
				applicant,
				forms.applications
			); // Utility function
			const isFormApplied = isApplied(form, applicant);
			if (formStatus) {
				return (
					<FormCard
						form={form}
						status={formStatus}
						key={form.id}
						isApplied={isFormApplied}
					/>
				);
			}
			return null;
		});
	};

	return (
		<div>
			<Header hideImage={false} username={localStorage.getItem("id")} />
			<img
				src={banner}
				alt="SVGA-banner"
				style={{ width: "80%", margin: "0 auto" }}
			/>
			<Typography variant="h6" style={{ fontWeight: "bold" }}>
				SVGA- Active Forms
				<hr />
			</Typography>
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{renderFormsDetails()}
			</div>
		</div>
	);
};

export default LandingPage;
