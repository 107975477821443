import * as yup from "yup";

interface Validation {
	type: string;
	params: any[];
}

interface Config {
	id: string;
	validationType: string;
	validations?: Validation[];
}

export function createYupSchema(
	schema: { [key: string]: yup.AnySchema },
	config: Config
): { [key: string]: yup.AnySchema } {
	const { id, validationType, validations = [] } = config;

	if (!(yup as any)[validationType]) {
		return schema;
	}
	let validator = (yup as any)[validationType]();

	validations.forEach((validation) => {
		const { params, type } = validation;

		if (type === "matches") {
			params[0] = new RegExp(params[0]);
		}

		if (!validator[type]) {
			return;
		}

		validator = validator[type](...params);
	});

	schema[id] = validator;
	return schema;
}
