import React, { FC, KeyboardEvent, ChangeEvent } from "react";
import { TextField, FormHelperText, Box } from "@mui/material";

interface TextfieldProps {
	type?: string;
	id?: string;
	label?: string;
	name: string;
	placeholder?: string;
	value: any;
	error?: any;
	disabled?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleBlur?: () => void;
	helperText?: string;
}

const Textfield: FC<TextfieldProps> = ({
	type,
	id,
	label,
	name,
	placeholder,
	value,
	error,
	disabled,
	onChange,
	handleBlur,
	helperText,
}) => {
	return (
		<Box>
			<form noValidate autoComplete="off">
				<TextField
					id={id}
					label={label}
					type={type}
					variant="outlined"
					placeholder={placeholder}
					name={name}
					value={value}
					onBlur={handleBlur}
					onChange={onChange}
					fullWidth
					disabled={disabled}
					error={!!error}
					size="small"
					onKeyPress={(e: KeyboardEvent) => {
						if (e.key === "Enter") e.preventDefault();
					}}
				/>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
				{error && <FormHelperText error>{error}</FormHelperText>}
			</form>
		</Box>
	);
};

export default Textfield;
