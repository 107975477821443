import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormHelperText, CircularProgress, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SERVER_URL_IMG } from "../../config";
import { AdditionalSettings } from "../../models/FormField";

interface UploadFieldProps {
	label?: string;
	name: string;
	value: any;
	error: any;
	formId: string;
	setFieldValue: (field: string, value: any) => void;
	handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	placeholder?: string;
	additionalSettings?: AdditionalSettings | undefined;
}

const UploadField: React.FC<UploadFieldProps> = (props) => {
	const {
		value,
		formId,
		name,
		setFieldValue,
		handleBlur,
		disabled,
		error,
		additionalSettings,
	} = props;
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const [selectedFileType, setSelectedFileType] = useState<string>("");
	const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
	const [touched, setTouched] = useState<boolean>(false);
	const [isError, setIsError] = useState<string | boolean>(false);
	const [isFileSubmit, setIsFileSubmit] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isFileSubmitMsg, setIsFileSubmitMsg] = useState<boolean>(false);
	const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(null);
	const FILE_SIZE = 10 * 1024 * 1024;
	const SUPPORTED_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
		"application/pdf",
	];
	const PREVIEW_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
	];
	const aadharNo = localStorage.getItem("id");
	let imageUploadURL = `${SERVER_URL_IMG}/api/upload/${aadharNo}/${formId}/${name}`;
	let imageDownloadURL = `${SERVER_URL_IMG}/api/get_file/${aadharNo}/${formId}/${value}?${lastUpdatedTime}`;

	if (
		additionalSettings !== undefined &&
		additionalSettings?.uploadEndpoint &&
		additionalSettings?.fileRetrieveEndpoint
	) {
		imageUploadURL = `${SERVER_URL_IMG}/api/upload_form_file/${formId}/${name}`;
		imageDownloadURL = `${SERVER_URL_IMG}/api/get_form_file/${formId}/${value}?${lastUpdatedTime}`;
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTouched(true);
		const file = e.target.files && e.target.files[0];
		if (file) {
			setFieldValue(name, file.name.replace("C:\\fakepath\\", ""));
			setIsFileSubmit(true);
			setSelectedFileSize(file.size);
			setSelectedFileType(file.type);
			setSelectedFile(file);
		} else {
			setFieldValue(name, undefined);
		}
	};

	useEffect(() => {
		if (touched && isFileSubmit) {
			setIsError(false);
			if (!value) {
				setIsError("Photo is required");
			} else if (
				!selectedFileType ||
				!SUPPORTED_FORMATS.includes(selectedFileType)
			) {
				setIsError(`File Type: ${selectedFileType}, Invalid File Type`);
			} else if (!(selectedFileSize && selectedFileSize <= FILE_SIZE)) {
				setIsError(
					`File Size: ${Math.floor(
						selectedFileSize / 1024 / 1024
					)}MB, File size should not be more than ${
						FILE_SIZE / 1024 / 1024
					}MB`
				);
			} else {
				setIsUploading(true);
				const formData = new FormData();
				formData.append("File", selectedFile!);
				fetch(imageUploadURL, {
					method: "POST",
					body: formData,
				})
					.then((response) => response.json())
					.then((result) => {
						setLastUpdatedTime(moment().toString());
						setFieldValue(name, result["filename"]);
						setIsFileSubmitMsg(true);
						setIsUploading(false);
					})
					.catch((error) => {
						setIsUploading(false);
					});
			}
			setIsFileSubmit(false);
		}
	}, [selectedFileType, selectedFileSize, value]);

	const CustomImageContainer = () => {
		if (
			value &&
			PREVIEW_FORMATS.includes(selectedFileType) &&
			isFileSubmitMsg
		) {
			return (
				<Box sx={{ overflow: "hidden" }}>
					<img
						src={imageDownloadURL}
						alt="Loading"
						style={{
							maxWidth: "100%",
							height: "auto",
							display: "block",
						}}
					/>
				</Box>
			);
		}
		if (value) {
			if (isUploading) {
				return (
					<div style={{ margin: 10 }}>
						<CircularProgress />
					</div>
				);
			}
			if (value.includes(".pdf")) {
				return (
					<a href={imageDownloadURL} target="_blank" rel="noreferrer">
						{"Click to view " + value}
					</a>
				);
			}
			return (
				<Box sx={{ overflow: "hidden", padding: 0.5 }}>
					<img
						src={imageDownloadURL}
						alt="Loading"
						style={{
							maxWidth: "100%",
							height: "auto",
							display: "block",
						}}
					/>
				</Box>
			);
		}
		return null;
	};

	return (
		<div>
			<Box>
				<Box sx={{ marginBottom: 1 }}>{props.label}</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "wrap",
					}}
				>
					<input
						type="file"
						name={name}
						id="contained-button-file"
						onBlur={handleBlur}
						onChange={handleChange}
						multiple={false}
						disabled={disabled}
					/>

					<CustomImageContainer />

					{isFileSubmitMsg && (
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<CheckCircleIcon
								style={{
									color: "green",
									fontSize: 15,
									marginTop: 5,
									marginRight: 5,
								}}
							/>
							<FormHelperText>
								{"File Submitted Successfully"}
							</FormHelperText>
						</Box>
					)}
				</Box>
				<FormHelperText>{props.placeholder}</FormHelperText>
				<FormHelperText error={true}>
					{isError ? isError : error}
				</FormHelperText>
			</Box>
			<hr />
		</div>
	);
};

export default UploadField;
