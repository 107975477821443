//src/models/FormField.ts
interface AdditionalSettings {
	uploadEndpoint?: any;
	fileRetrieveEndpoint?: any;
	// Add more keys as needed
}

class FormField {
	key: string;
	additionalSettings: AdditionalSettings | undefined;

	constructor(
		public id: string,
		public label: string,
		public placeholder: string,
		public type: string = "text",
		public validationType: string = "string",
		public value: string = "",
		public mandatory: boolean = true,
		public options: any[] = [],
		public validations: any[] = [], // Define specific type if available
		public preview: boolean = true,
		public helperText: string = "",
		public isProfile?: any, // Define specific type if available
		public isEditableProfile?: any, // Define specific type if available
		public rules: { [key: string]: any } = {}, // Define specific type if available
		public action: any[] = [], // Define specific type if available
		public elementWidth: number = 12,
		additionalSettings: AdditionalSettings | undefined = undefined
	) {
		this.key = id;
		// Set additionalSettings to undefined if input is undefined
		this.additionalSettings = additionalSettings
			? this.validateAdditionalSettings(additionalSettings)
			: undefined;
	}

	private validateAdditionalSettings(
		additionalSettings: AdditionalSettings | undefined
	): AdditionalSettings | undefined {
		// If additionalSettings is null, return null
		if (!additionalSettings) {
			return undefined;
		}

		// Validate additionalSettings keys
		const allowedKeys: AdditionalSettings = {
			uploadEndpoint: true,
			fileRetrieveEndpoint: true,
			// Add more allowed keys as needed
		};

		return Object.keys(additionalSettings).reduce(
			(acc: AdditionalSettings, key: string) => {
				if (allowedKeys[key as keyof AdditionalSettings]) {
					acc[key as keyof AdditionalSettings] =
						additionalSettings[key as keyof AdditionalSettings];
				} else {
					console.warn(
						`Ignoring unknown additional setting key: ${key}`
					);
				}
				return acc;
			},
			{} as AdditionalSettings
		);
	}

	static createFormFieldSpacer(id: string) {
		return new FormField(
			id + "-spacer",
			"",
			"",
			"pagebreak",
			"",
			"",
			false,
			[],
			[],
			false,
			"",
			"",
			"",
			{},
			[],
			12
		);
	}
}

export { FormField, AdditionalSettings };
