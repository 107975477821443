import { APPROVE_STATE } from "./ApproversResponses";
import { ApproverUser, CLAIMS } from "./LoginUsers";
import { ApproversResponses, ApproverResponse } from "./ApproversResponses";

// Class for managing approvals leaf
class ApprovalsLeaf {
	constructor(
		public leafId: string,
		public name: string = "",
		public approvers: ApproverUser[] = [],
		public allowedClaims: string[] = [],
		public mandatory: boolean = false,
		public atleast: number = 1,
		public leafStatus: boolean = true,
		public allowReapprovalUponDecline: boolean = false,
		public allowEditingUponDecline: boolean = false
	) {
		this.status = undefined;
		this.responses = new ApproversResponses({}); // Initialize responses with an empty object
	}

	parseObject(): void {
		const temp: ApproverUser[] = [];
		this.approvers.map((approver) => {
			let approvers_obj = Object.assign(
				new ApproverUser("", "", [CLAIMS.APPROVER.value]),
				approver
			);
			approvers_obj.updateResponse(this.responses);
			temp.push(approvers_obj);
		});
		this.approvers = temp;
	}

	getApprover(id: string): ApproverUser {
		return this.approvers[0];
	}

	getApprovalInfo(): string {
		const comment = this.getApproverComment();
		let info = `Allowed roles : ${this.allowedClaims.join(",")} \n`;
		if (comment) {
			info += comment;
		}
		return info;
	}

	isValidApprover(loginUserObj: ApproverUser): boolean {
		// Consider replacing 'any' with the specific type for loginUserObj
		return loginUserObj.checkIsClaimsAllowed(this.allowedClaims);
	}

	getApproverComment(): string | undefined {
		if (this.getLeafResponse() !== undefined) {
			return this.getLeafResponse()?.getApproverComment();
		}
	}

	getApplicantMessage(): string | undefined {
		if (this.getLeafResponse() !== undefined) {
			return this.getLeafResponse()?.comment;
		}
	}

	getLeafResponse(): ApproverResponse | undefined {
		if (this.responses?.responses === undefined) {
			return undefined;
		}
		return this.responses.responses[this.leafId];
	}

	getApprovalStatus(forceReload: boolean = false): string | undefined {
		if (!forceReload && this.status !== undefined) {
			return this.status;
		}
		if (this.getLeafResponse() !== undefined) {
			return this.getLeafResponse()?.status;
		}
		return APPROVE_STATE.PENDING.value;
	}

	isEditingUponDecline(): boolean {
		return (
			this.allowEditingUponDecline &&
			this.getApprovalStatus() === APPROVE_STATE.DENIED.value
		);
	}

	public status: string | undefined;
	public responses: ApproversResponses;
}

export { ApprovalsLeaf };
