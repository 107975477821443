import React from "react";
import { Typography, Box } from "@mui/material";

interface BlankFieldProps {}

const BlankField: React.FC<BlankFieldProps> = (props) => {
	return <Box></Box>;
};

export default BlankField;
