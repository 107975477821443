import moment, { Moment } from 'moment';

class ProfileField {
	updated_timestamp: Moment;
	constructor(
		public id: string,
		public name: string,
		public value: any, // Define specific type if available
		public isEditable: boolean,
		public formId: string = "",
		public submitted: boolean = false,
	) {
		this.updated_timestamp = moment()
	}
}

export { ProfileField };
